<template>
    <div class="page-header">
        <section class="page-header__mobile-client-selector">
            <button class="page-header__mobile-button" @click="mobileMenuOpen">
                <span class="material-symbols">menu</span>
            </button>

            <div class="select select--page-header page-header__select">
                <div class="select__arrow">
                    <span class="material-symbols">arrow_drop_down</span>
                </div>

                <select
                    v-model="selectedClientId"
                    class="select__select"
                >
                    <option
                        v-if="clients.length !== 1"
                        :value="null"
                    >
                        Select a client
                    </option>

                    <option
                        v-for="client in clients"
                        :value="client.id"
                    >
                        {{ client.name }}
                    </option>
                </select>
            </div>
        </section>

        <section class="page-header__greeting">
            <Link
                v-if="titleRoute"
                :href="titleRoute"
                :data="titleRouteParams"
                as="h1"
                class="page-header__title"
            >
                {{ title }}
            </Link>
            <h1
                v-else
                class="page-header__title"
                data-test="page-header-title"
            >
                {{ title }}
            </h1>

            <span
                v-if="subTitle"
                class="page-header__subtitle"
            >
                {{ subTitle }}
            </span>

            <div class="select select--page-header page-header__select">
                <div class="select__arrow">
                    <span class="material-symbols">arrow_drop_down</span>
                </div>

                <select
                    v-model="selectedClientId"
                    class="select__select"
                >
                    <option
                        v-if="clients.length !== 1"
                        :value="null"
                    >
                        Select a client
                    </option>

                    <option
                        v-for="client in clients"
                        :value="client.id"
                    >
                        {{ client.name }}
                    </option>
                </select>
            </div>
        </section>
    </div>
</template>

<script>
import { datadogRum } from '@datadog/browser-rum';
import { router } from '@inertiajs/vue2';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },

        titleRoute: {
            type: String,
            required: false,
            default: null,
        },

        titleRouteParams: {
            type: Object,
            default: () => {
            },
        },

        subTitle: {
            type: String,
            required: false,
            default: null,
        },

        reloadOptions: {
            type: Object,
            required: false,
            default: () => {
            },
        },
    },

    computed: {
        clients() {
            return this.$page.props.clients;
        },

        // showSingleOption() {
        //     return this.clients.length === 1;
        // },

        clientId() {
            return this.$page.props.filter.client_id;
        },

        selectedClientId: {
            get() {
                // if (this.showSingleOption) {
                //     return this.clients[0].id;
                // }
                return this.clientId;
            },

            set(value) {
                const options = this.reloadOptions ?? {};

                if (window.Datadog?.enabled) {
                    if (window.Datadog.user) {
                        datadogRum.setUser({
                            id: window.Datadog.user.id,
                            name: window.Datadog.user.name,
                            email: window.Datadog.user.email,
                            client_id: window.Datadog.client_id,
                            client_name: window.Datadog.client_name,
                        });
                    }
                }

                if (options.only) {
                    // make sure the filter and client related data is always fetched
                    options.only.push('filter');
                    options.only.push('menu');
                    options.only.push('campaigns');
                    options.only.push('contact');
                }

                if (!options.data) {
                    options.data = {};
                }

                options.data.client = value;

                this.$nextTick(() => {
                    this.$inertia.reload(options);
                });
            },
        },
    },

    methods: {
        mobileMenuOpen() {
            this.$root.$emit('open-menu');
        },

        clickableHeader() {
            const header = document.querySelector('.page-header__greeting');

            if (header.querySelector('.page-header__subtitle') !== null) {
                header.querySelector('.page-header__title').classList.add('clickable');
            }
        },
    },

    mounted() {
        this.clickableHeader();
    },
};
</script>

<style lang="scss">
@import './resources/assets/scss/shared/extends/multi-load/multi-load',
'page-header';
</style>

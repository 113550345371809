<template>
    <button
        class="portal-button"
        :class="{
            'portal-button--fit-content': fitContent,
            [`portal-button--${type}`]: true
        }"
        :disabled="disabled"
        :type="btnType"
        @click="$emit('click')"
    >
        <span
            v-if="icon"
            class="portal-button__icon material-symbols-outlined"
        >
            {{ icon }}
        </span>

        <span class="portal-button__label">
            <slot></slot>
        </span>
    </button>
</template>

<script>

export default {
    props: {
        icon: {
            type: String,
            required: false,
        },

        type: {
            type: String,
            required: false,
            default: 'primary',
        },

        fitContent: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        btnType: {
            type: String,
            required: false,
            default: 'submit',
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'portal-button.scss';
</style>
